import React, { Suspense } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { isSSR, actualCountry } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"
import Resource from "../config/language.json"
import CardResource from "../components/ProductDetail/language.json"

const ProductDetail = React.lazy(() => import("../components/ProductDetail"))
const component = Resource[process.env.GATSBY_SITE_VERSION]
const adicionalComponent = CardResource[process.env.GATSBY_SITE_VERSION]

export default () => {
  const data = useStaticQuery(graphql`
    query {
      solutionMx: file(
        relativePath: {
          eq: "mx/landings/solucion-creditocredito-liquidez-con-garantia-hipotecaria-iban-online-mexico.jpg"
        }
      ) {
        ...customFragmentImageFluid
      }
      solutionDo: file(
        relativePath: {
          eq: "do/landings/prestamo-para-saldar-deudas-iban-online.jpg"
        }
      ) {
        ...customFragmentImageFluid
      }
    }
  `)

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title={component.solutionCredit.SEO.title}
            keywords={component.solutionCredit.SEO.keywords}
            description={component.solutionCredit.SEO.description}
          />
          <Layout>
            <ProductDetail
              pageData={{
                alt: component.solutionCredit.pageData.alt,
                credit: component.solutionCredit.pageData.credit,
                minAmount: component.solutionCredit.minAmount,
                minLoanDurationInMonths:
                  component.solutionCredit.minLoanDurationInMonths,
                titleProduct: component.solutionCredit.pageData.titleProduct,
                textProduct: component.solutionCredit.pageData.textProduct,
                href: component.solutionCredit.pageData.href,
                textBenefits: component.solutionCredit.pageData.textBenefits,
                image:
                  actualCountry === "do"
                    ? data.solutionDo.childImageSharp.fluid
                    : data.solutionMx.childImageSharp.fluid,
                cardDataUp: adicionalComponent.cardSolutionCredit,
                partner: component.solutionCredit.pageData.partner,
                form: component.solutionCredit.form.inputs,
                applicationType:
                  component.solutionCredit.pageData.applicationType,
                cardDataHeader:
                  component.solutionCredit.pageData.cardDataHeader,
              }}
            />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
